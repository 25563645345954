.drawer {
  .drawerPaper {
    display: flex;
    flex-direction: column;

    .drawerHeader {
      display: flex;
      justify-content: flex-end;

      .label {
        font-size: 25px;
      }
    }

    .drawerContent {
      width: 900px;
      padding: 15px;
      overflow-y: scroll;

      .freqStatusContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fieldContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
        }

        .rfaExportContainer {
          display: flex;
        }
      }

      .antennaContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .fields {
          display: flex;
          gap: 10px;
        }
      }

      .orientationContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .fields {
          display: flex;
          gap: 10px;
        }
      }

      .section {
        border: 1px solid gray;
        border-radius: 10px;
        margin-top: 20px;

        .titleContainer {
          width: 100%;
          display: flex;
          padding: 5px 10px;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid gray;

          .title {
            font-size: 25px;
            font-weight: 600;
          }
        }

        .assignedFreq {
          width: 100%;
          display: flex;
          margin: 10px 0px;
          justify-content: space-around;

          .input {
            width: 150px;
          }

          .select {
            width: 180px;
          }

          .frequencyInput {
            width: 150px;

            .select {
              width: 130px;
              font-size: 12px;
            }

            & > div {
              padding-right: 0px;
            }
          }
        }
      }

      .equipment {
        border: 1px solid gray;
        border-radius: 10px;
        margin: 10px 50px;

        .titleContainer {
          width: 100%;
          padding: 5px 10px;
          border-bottom: 1px solid gray;
        }

        .fieldContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 20px;

          .f_501 {
            display: flex;
            gap: 10px;
          }
        }
      }

      .actions {
        display: flex;
        gap: 10px;
        padding: 0px 10px;
        margin-top: 20px;

        .generateBtn {
          flex: 1;
        }

        .exportBtn {
          flex: 1;
        }
      }

      .sfafContainer {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.option {
  font-size: 12px;
}
