.sidebar {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  height: 100vh;
}

.container {
  position: relative;
  height: 100%;
}

.box {
  padding: 10px 20px;
  overflow-y: scroll;
  transition:
    width 0.5s,
    padding 0.5s;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
  height: 100%;

  .btn {
    font-size: 12px;
  }

  .actionsContainer {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  .customPoints {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .customPointContainer {
      border: 1px solid gray;
      border-radius: 10px;
      padding: 5px;

      .title {
        font-size: 13px;
      }

      .actions {
        display: flex;
        gap: 15px;
        align-items: center;

        .btns {
          display: flex;
          gap: 10px;

          .btn {
            font-size: 12px;
          }
        }
      }
    }
  }

  .label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.closed {
    width: 0;
    padding: 10px 0;
  }
}

.arrowButton {
  position: absolute;
  top: 50%;
  left: -40px;
  z-index: 5000;
  transform: translateY(-50%) rotate(0);
  transition: transform 0.5s;

  .icon {
    width: 40px;
    height: 40px;
    color: white;
  }

  &.closed {
    transform: translateY(-50%) rotate(-180deg);
  }

  > div {
    padding: 2px;
    cursor: pointer;

    &:hover {
      transition: transform 0.3s;
      transform: scale(1.1);
    }
  }
}

.heatmapButton {
  position: absolute;
  top: 8px;
  width: max-content;
  transition: left 0.5s;
  display: flex;
  gap: 8px;

  > button {
    color: rgb(22, 119, 56);
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 6px 0;

  input,
  select {
    border: 1px solid #ccc;
    outline: none;
    padding: 2px 4px;
    min-width: 100px;
  }
}

.frequencies {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.frequencySlider {
  padding: 0 12px;
}

.customFilters {
  overflow-y: auto;
  max-height: 200px;
}
