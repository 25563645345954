:root {
  --border: 1px solid black;
  --text-color: #000;
  --background-color: #fff;
  --drawer-icon-border: 1px solid rgba(0, 0, 0, 0.2);
  --input-background-color: white;
  --header-background-color: rgb(22, 119, 56);
  --drawer-icon-background-color: #fff;
  --sub-container-background-color: #fff;
}

.light-mode {
  --border: 1px solid black;
  --text-color: #000;
  --background-color: #fff;
  --drawer-icon-border: 1px solid rgba(0, 0, 0, 0.2);
  --input-background-color: white;
  --header-background-color: rgb(22, 119, 56);
  --drawer-icon-background-color: #fff;
  --sub-container-background-color: #fff;
}

.dark-mode {
  --border: 1px solid white;
  --text-color: #fff;
  --background-color: #04111a;
  --drawer-icon-border: 1px solid #fff;
  --input-background-color: #13222e;
  --header-background-color: #13222e;
  --drawer-icon-background-color: rgb(19, 34, 46);
  --sub-container-background-color: #13222e;
}

.main-container,
.sub-container {
  background-color: var(--background-color);
  color: var(--text-color);
}

.sub-container {
  background-color: var(--sub-container-background-color);
}

.border {
  border: var(--border);
}

.header {
  background-color: var(--header-background-color);
}

.input {
  background-color: var(--input-background-color);
}

.drawerIcon {
  background: var(--drawer-icon-background-color);
  border: var(--drawer-icon-border);
}
